











import { Component, Mixins } from 'vue-property-decorator';
import { ListingFilter } from 'client-website-ts-library/filters';
import { ListingStatus, OpenHomes } from 'client-website-ts-library/types';
import { API } from 'client-website-ts-library/services';
import { View } from 'client-website-ts-library/plugins';

import ListingCard from '../components/ListingCard.vue';
import Loader from '../components/UI/Loader.vue';

@Component({
  components: {
    ListingCard,
    Loader,
  },
})
export default class AuctionLive extends Mixins(View) {
  mounted() {
    let scr: HTMLScriptElement | null = document.getElementById('scrAuctionsliveMainDiv') as HTMLScriptElement | null;
    if (scr) scr.remove();

    setTimeout(() => {
      scr = document.createElement('script');
      scr.src = 'https://auctionslive.com/w/js/auctionslive_widget.min.js';
      scr.async = true;
      scr.defer = true;
      scr.id = 'scrAuctionsliveMainDiv';

      scr.onload = () => {
        console.log('');
      };

      document.body.append(scr);
    }, 500);
  }
}
